<template>
    <div class="receive-invite">
        <van-nav-bar
            title="接受邀请"
            fixed
            left-arrow
            @click-left="$router.go(-1)"
        />
        <div class="box">
            <div class="bg"></div>
            <div class="friend">
                <div class="headimage">
                    <img :src="userAvatar(friendInfo.headimage)" alt="">
                </div>
                <div class="friend-name">
                    {{ friendInfo.username }}
                </div>
            </div>
            <div class="invite-words">
                <span>邀请您一起学习～</span>
            </div>
            <div class="accept-btn">
                <van-button block round @click="join" type="primary">接受邀请</van-button>
            </div>
        </div>
    
    </div>
      
    </template>
    <script>
    // import { Base64 } from 'js-base64';
    import cfg from '../config';
    import { Toast } from 'vant';
    export default {
      name: "ReceiveInvite",
      data() {
        return {
          inviteCode: '',
          friendInfo: {},
        };
      },
      components:{
          
      },
      mounted() {
        if(this.$route.params.i){
            this.inviteCode = this.$route.params.i;
        }
        this.getFriendInfo();
        
      },
      methods: {
        userAvatar(url){
            if(url){
                return /^http/.test(url) ? url : cfg._ASSETS_HOST + url;
            }else{
                return require("../assets/logo.png");
            }
        },
        getFriendInfo(){
            let data = {
                i: this.inviteCode
            };
            let _this = this;
            this.$http.call(this.$http.api.friendInfo,{data:data}).then(function(res){
                let resp = res.data;
                console.log(resp)
                if(resp.code=='0'){
                    _this.friendInfo = resp.data;
                }else{
                    Toast(resp.msg);
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        join(){
            console.log('join...', this.inviteCode);
            if(this.friendInfo && this.friendInfo.id>0){
                this.$store.commit("setReferer", this.inviteCode);
                this.$router.push({name: 'weauth', query: {i: this.inviteCode}});
            }else{
                Toast("邀请者不存在");
            }
        },
      },
    };
    </script>
    <style lang="less" scoped>
    .receive-invite{
        padding: 70px 15px;
        .box {
            background: #fff;
            border-radius: 15px;
            margin: 60px 15px;
            padding: 20px 20px 50px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #333;
            line-height: 40px;
            .friend {
                display: flex;
                align-items: center;
                flex-direction: column;
                .headimage{
                    width: 75px;height: 75px;
                    img {
                        width: 100%;
                    }
                }
                .friend-name {
                    color: #999;
                }
            }
            .invite-words {
                padding: 15px 0;
                font-size: 16px;
                font-weight: bold;
                color: #999;
                font-style: italic;
            }
            
        }
    }
    
    </style>
    